.react-tagsinput-tag {
    background-color: #607d8b;
    border-radius: 5px;
    border: 1px solid #607d8b;
    color: #ffffff;
    display: inline-block;
    font-family: sans-serif;
    font-size: 15px;
    font-weight: 700;
    margin: 5px;

    padding: 5px 15px;
    text-transform: capitalize;
}

.interest .react-tagsinput-tag {
    background-color: #ffffff;
    color: #607d8b;
}

.react-tagsinput {
    border: none;
    padding-left: 0;
}

.react-tagsinput-remove {
    display: none;
}

@media print {
    #printContainer {
        width: '210mm';
        height: '297mm';
    }
}